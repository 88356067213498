import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { PagedResultDtoOfFormListDto, CollectionFormListDto, CollectionFormItemDto } from "./model";
import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
export class CollectionFormServiceProxy {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver:
        | ((key: string, value: any) => any)
        | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    /**
     * 获取单条详情
     * @param body (optional)
     * @return Success
     */
    async getForEdit(id: string | undefined): Promise<CollectionFormListDto> {
        let url_ = this.baseUrl + "/api/services/app/Form/GetEditById?id=" + id;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetForEdit(_response);
    }
    protected processGetForEdit(
        response: AxiosResponse
    ): Promise<CollectionFormListDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = CollectionFormListDto.fromJS(resultData200);
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<CollectionFormListDto>(<any>null);
    }
    async getFormItem(id: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Form/GetFormItem?id=" + id;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetFormItem(_response);
    }
    protected processGetFormItem(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            console.log(response)
            // result200 = CollectionFormItemDto.formJson(resultData200);
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }

    /**
     * 添加或者修改的公共方法
     * @param body (optional)
     * @return Success
     */
    async createOrUpdate(body: CollectionFormListDto | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/form/Save";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    //添加子项
    async addItem(body: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/form/AddItem";
        url_ = url_.replace(/[?&]$/, "");

        // const content_ = JSON.stringify(body);
        console.log(body);
        const content_ = body;
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    async updateItem(body: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/form/UpdateItem";
        url_ = url_.replace(/[?&]$/, "");

        // const content_ = JSON.stringify(body);
        console.log(body);
        const content_ = body;
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "PUT",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    async changeStatus(body: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/form/ChangeStatus";
        url_ = url_.replace(/[?&]$/, "");

        // const content_ = JSON.stringify(body);
        console.log(body);
        const content_ = body;
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    getProject(): Promise<any>{
        let url = this.baseUrl + '/api/services/app/Organization/GetSelectList';
        let options = <AxiosRequestConfig> {
          params: { },
          method: "GET",
          url: url,
          headers: {
              "Content-Type": "application/json-patch+json",
          }
        };
        return this.instance.request(options).then((response: AxiosResponse) => {
          let status = response.status;
          if (status === 200) {
            return Promise.resolve<void>(<any> response.data);
          } else {
            const responseText = response.data;
            return throwException("An unexpected server error occurred.", status, responseText, null);
          }
        });
      }
    protected processCreateOrUpdate(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<any>(<any>response);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>response);
    }

    /**
     * 分页列表信息
     * @param filterText (optional)
     * @param sorting (optional)
     * @param maxResultCount (optional)
     * @param skipCount (optional)
     * @return Success
     */
    async getPaged(
        filterText: string | undefined,
        formType: number | undefined,
        formStatus:number | undefined,
        sorting: string | undefined,
        maxResultCount: number | undefined,
        skipCount: number | undefined
    ): Promise<PagedResultDtoOfFormListDto> {
        let url_ = this.baseUrl + "/api/services/app/Form/GetPaged?";
        if (filterText === null) {
            throw new Error("The parameter 'filterText' cannot be null.");
        } else if (filterText !== undefined) {
            url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
        }
        if (formType != null) {
            url_ += "type=" + formType + "&";
        }
        if (formStatus != null) {
            url_ += "Status=" + formStatus + "&";
        }
        if (sorting === null) {
            throw new Error("The parameter 'sorting' cannot be null.");
        } else if (sorting !== undefined) {
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        }
        if (maxResultCount === null) {
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        } else if (maxResultCount !== undefined) {
            url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        }
        if (skipCount === null) {
            throw new Error("The parameter 'skipCount' cannot be null.");
        } else if (skipCount !== undefined) {
            url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPaged(_response);
    }

    protected processGetPaged(
        response: AxiosResponse
    ): Promise<PagedResultDtoOfFormListDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = PagedResultDtoOfFormListDto.fromJS(resultData200);
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<PagedResultDtoOfFormListDto>(<any>null);
    }

    /**
     * excel文件
     * @return Success
     */
    async getToExcelFile(): Promise<FileDto> {
        let url_ = this.baseUrl + "/api/services/app/Company/GetToExcelFile";
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetToExcelFile(_response);
    }

    protected processGetToExcelFile(response: AxiosResponse): Promise<FileDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = FileDto.fromJS(resultData200);
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<FileDto>(<any>null);
    }

    /**
     * 删除信息
     * @param id (optional)
     * @return Success
     */
    removeItem(id: string | undefined): Promise<void> {
        console.log(id);
        let url_ = this.baseUrl + "/api/services/app/Form/RemoveItem?";
        if (id === null) {
            throw new Error("The parameter 'id' cannot be null.");
        } else if (id !== undefined) {
            url_ += "id=" + encodeURIComponent("" + id) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        console.log(url_);
        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {},
        };

        return this.instance.request(options_).then((_response: AxiosResponse) => {
            return this.processDelete(_response);
        });
    }
    deleteCollectionForm(id: string | undefined): Promise<void> {
        console.log(id);
        let url_ = this.baseUrl + "/api/services/app/Form/DeleteCollectionForm?";
        if (id === null) {
            throw new Error("The parameter 'id' cannot be null.");
        } else if (id !== undefined) {
            url_ += "id=" + encodeURIComponent("" + id) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        console.log(url_);
        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {},
        };

        return this.instance.request(options_).then((_response: AxiosResponse) => {
            return this.processDelete(_response);
        });
    }
    protected processDelete(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<void>(<any>null);
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): any {
    if (result !== null && result !== undefined) {
        throw result;
    } else {
        throw new SwaggerException(message, status, response, headers, null);
    }
}
